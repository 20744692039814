<template>
  <!-- Main jumbotron for a primary marketing message or call to action -->

  <section id="nosotros-ayudamos">
    <div class="container jumbotron pt-5">
      <div class="row justify-content-center">
        <div class="col-10">
          <h2 class="section-title">
            Política de privacidad y protección de datos.
          </h2>
          <p>
            En cumplimiento del Reglamento (UE) 2016/679 del Parlamento Europeo
            y del Consejo, de 27 de abril de 2016, relativo a la protección de
            las personas físicas en lo que respecta al tratamiento de datos
            personales, por el que se deroga la directiva 95/46/CE (en adelante,
            RGPD), de la Ley 34/2002, de 11 de julio, de servicios de la
            sociedad de la información y de comercio electrónico (en adelante,
            LSSI-CE) y de la Ley Orgánica 3/2018, de Protección de Datos
            Personales y garantía de los derechos digitales, IBANCAR WORLD SL
            garantiza la protección y confidencialidad de los datos personales,
            de cualquier tipo que nos proporcionen nuestros clientes, de acuerdo
            con lo dispuesto en el Reglamento General de Protección de Datos de
            Carácter Personal.<br />
            La Política de Protección de Datos de IBANCAR WORLD SL descansa en
            el principio de responsabilidad proactiva, según el cual el
            Responsable del tratamiento es responsable del cumplimiento del
            marco normativo y jurisprudencial, siendo capaz de demostrarlo ante
            las autoridades de control correspondientes. Los datos facilitados
            serán tratados en los términos establecidos en el RGPD; en este
            sentido, IBANCAR WORLD SL ha adoptado los niveles de protección que
            legalmente se exigen, y ha instalado todas las medidas técnicas a su
            alcance para evitar la pérdida, mal uso, alteración, acceso no
            autorizado por terceros, expuestos a continuación. No obstante, el
            usuario debe ser consciente de que las medidas de seguridad en
            Internet no son inexpugnables.<br />
          </p>
          <h5>Responsable del tratamiento: ¿Quiénes somos?</h5>
          <p>
            Denominación: IBANCAR WORLD SL<br />
            CIF: B93145001<br />
            Dirección: Avenida Velázquez 89, portal 87, código postal 29004, Málaga<br />
            Teléfono: 952864645<br />
            Correo electrónico: baja@ibancar.com<br />
            Delegado de Protección de Datos: ClickDatos Seguridad de la Información SL.<br/>
          </p>
          <h5>Finalidad del tratamiento: ¿Para qué usaremos sus datos?</h5>
          <p>
            Todos los datos facilitados por nuestros clientes y/o visitantes en
            la web de IBANCAR WORLD SL o a su personal, serán incluidos en
            ficheros, automatizados o no, creados y mantenidos bajo la
            responsabilidad del mismo, imprescindibles para prestar los
            servicios solicitados por los usuarios, o para resolver las dudas o
            cuestiones planteadas por nuestros visitantes.
          </p>
          <h5>Legitimidad del tratamiento: ¿Por qué necesitamos sus datos?</h5>
          <p>
            a) Relación contractual: Es la que aplica cuando contrata alguno de
            nuestros servicios.<br />
            b) Interés legítimo: Para atender a las consultas y reclamaciones
            que nos plantee y para gestionar el cobro de las cantidades
            adeudadas.<br />
            c) Su consentimiento: Si es usuario de nuestra web, mediante la
            marcación de la casilla que figura en el formulario de contacto, nos
            autoriza a que le remitamos las comunicaciones necesarias para dar
            respuesta a la consulta o solicitud de información planteada.<br />
          </p>
          <h5>Destinatarios: ¿Con quién compartimos sus datos?</h5>
          <p>
            Cedemos sus datos personales a terceros necesarios para alcanzar la finalidad del tratamiento. 
            Asimismo, cedemos sus datos a terceras empresas con la finalidad de que estas realicen comunicaciones comerciales a nuestros clientes. 
            También los cedemos a entidades públicas o privadas a las cuales estemos obligados a facilitar sus datos personales con motivo del cumplimiento de alguna ley. 
            Por poner un ejemplo, la Ley Tributaria obliga a facilitar a la Agencia Tributaria determinada información sobre operaciones económicas que superen una determinada cantidad.
            En el caso de que, al margen de los supuestos comentados, necesitemos dar a conocer su información personal a otras entidades, le solicitaremos previamente su permiso a través de opciones claras que le permitirán decidir a este respecto.
          </p>
          <h5>Comunicación: ¿Dónde podríamos enviar tus datos?</h5>
          <p>
            No realizaremos transferencias internacionales de sus datos
            personales para ninguna de las finalidades indicadas.
          </p>
          <h5>Conservación: ¿Cuánto tiempo mantendremos tus datos?</h5>
          <p>
            Solo conservaremos sus datos personales durante el tiempo que
            resulte necesario para lograr los fines para los que fueron
            recabados. A la hora de determinar el oportuno periodo de
            conservación, examinamos los riesgos que conlleva el tratamiento,
            así como nuestras obligaciones contractuales, legales y normativas,
            las políticas internas de conservación de datos y nuestros intereses
            de negocio legítimos descritos en el presente Aviso de Privacidad y
            Política de Cookies.<br />
            En este sentido, IBANCAR WORLD SL conservará los datos personales
            una vez terminada su relación con Ud., debidamente bloqueados,
            durante el plazo de prescripción de las acciones que pudieran
            derivarse de la relación mantenida con el interesado.<br />
            Una vez bloqueados, sus datos resultarán inaccesibles para IBANCAR
            WORLD SL, y no serán tratados excepto para su puesta a disposición a
            las Administraciones públicas, Jueces y Tribunales, para la atención
            de las posibles responsabilidades nacidas de los tratamientos, así
            como para el ejercicio y defensa de reclamaciones ante la Agencia
            Española de Protección de Datos. <br />
          </p>
          <h5>Seguridad: ¿Cómo vamos a proteger sus datos?</h5>
          <p>
            Empleamos todos los esfuerzos razonables para mantener la
            confidencialidad de la información personal que se trate en nuestros
            sistemas. Mantenemos estrictos niveles de seguridad para proteger
            los datos de carácter personal que procesamos frente a pérdidas
            fortuitas y a accesos, tratamientos o revelaciones no autorizados,
            habida cuenta del estado de la tecnología, la naturaleza y los
            riesgos a que están expuestos los datos. No obstante, no podemos
            responsabilizarnos del uso que Ud. haga de los datos (incluido
            usuario y contraseña) que utilice en nuestra web. Nuestro personal
            sigue estrictas normas de privacidad y en el caso de que contratemos
            a terceros para prestar servicios de soporte, les exigimos que
            acaten las mismas normas y nos permitan auditarles para verificar su
            cumplimiento.
          </p>
          <h5>Sus derechos: ¿Qué derechos puede ejercer como interesado?</h5>
          <p>
            Le informamos que podrá ejercer los siguientes derechos:<br />
            a) Derecho de acceso a sus datos personales, para saber cuáles están
            siendo objeto de tratamiento y las operaciones de tratamiento
            llevadas a cabo con ellos;<br />
            b) Derecho de rectificación de cualquier dato personal inexacto;<br />
            c) Derecho de supresión de sus datos personales, cuando esto sea
            posible (por ejemplo, por imperativo legal);<br />
            d) Derecho de limitación del tratamiento de sus datos personales
            cuando la exactitud, la legalidad o la necesidad del tratamiento de
            los datos resulte dudosa, en cuyo caso, podremos conservarlos para
            el ejercicio o la defensa de reclamaciones.<br />
            e) Derecho de oposición al tratamiento de sus datos personales,
            cuando la base legal que nos habilite para su tratamiento de las
            indicadas sea nuestro interés legítimo. IBANCAR WORLD SL dejará de
            tratar tus datos salvo que tenga un interés legítimo o sea necesario
            para la defensa de reclamaciones, cuando proceda este derecho.<br />
            f) Derecho a la portabilidad de sus datos, cuando la base legal que
            nos habilite para su tratamiento sea la existencia de una relación
            contractual o su consentimiento.<br />
            g) Derecho a revocar el consentimiento otorgado a IBANCAR WORLD S.L<br />
            Para ejercitar sus derechos, puede hacerlo de manera gratuita y en cualquier momento, escribiéndonos a baja@ibancar.com, firmando el documento de solicitud con su certificado digital o solicitándonos y rellenando el formulario de derecho correspondiente.<br />
          </p>
          <h5>Tutela de derechos: ¿Dónde puede formular una reclamación?</h5>
          <p>
            En caso de que entienda que sus derechos han sido desatendidos por
            nuestra entidad, puede formular una reclamación en la Agencia
            Española de Protección de Datos, a través de alguno de los medios
            siguientes:<br />
            .- Sede electrónica: https://www.aepd.es<br />
            .- Correo postal: Agencia Española de Protección de Datos, C/ Jorge
            Juan, 6, 28001, Madrid<br />
            .- Teléfono: 901.100.099 y 912.663.517<br />
            Formular una reclamación en la Agencia Española de Protección de
            Datos no conlleva ningún coste y no es necesaria la asistencia de
            abogado ni procurado
          </p>
          <h5>
            Actualizaciones: ¿Qué cambios puede haber en esta Política de
            Privacidad?
          </h5>
          <p>
            BANCAR WORLD SL se reserva el derecho a modificar la presente
            política para adaptarla a novedades legislativas o jurisprudenciales
            que puedan afectar el cumplimiento de la misma.<br/> Con objeto de
            garantizar el cumplimiento de la normativa sobre protección de datos
            de carácter personal, IBANCAR WORLD SL ha recibido los servicios de
            consultoría y asesoramiento por parte de ClickDatos.
          </p>
        </div>
        <!-- /row1 -->
      </div>
    </div>
    <!-- /container -->
  </section>
  <!-- /section -->
</template>

<script>
import { useMeta } from 'vue-meta'
export default {
  name: "Privacidad",
  setup() {
    useMeta({
      title: 'Privacidad',
      link: [
        { href: 'https://ibancar.com/politica-privacidad', rel: 'alternate', hreflang: 'es-es' },
        { href: 'https://ibancar.mx/politica-privacidad', rel: 'alternate', hreflang: 'es-MX' }
      ]
    })
  }
};
</script>

<style scoped lang="scss">
.jumbotron {
  padding: 25px 0 0 25px;
  margin-top: 90px;
  background-color: #fff;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-between(xs, sm) {
}

@include media-breakpoint-between(md, lg) {
}

@include media-breakpoint-between(xl, xxl) {
}
</style>